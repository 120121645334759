.selected,
.selected:hover {
  color: #fff;
  background-color: #bc2f2c;
  border: 1px solid rgba(0, 0, 0, 0);
}

.small .pl {
  padding-left: 8px;
}

.normal .pl {
  padding-left: 16px;
}

.large .pl {
  padding-left: 24px;
}
