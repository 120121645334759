.uploading {
  opacity: 0.5;
}

.older {
  opacity: 0.3 !important;
  cursor: default !important;
}

.older:hover {
  opacity: 1 !important;
}

.success {
  opacity: 1;
}

.error {
}

.removing {
  opacity: 0.5;
}

.removed {
}

.docCard {
  opacity: 0.3;
}

.docCard:nth-child(2),
.docCard:hover {
  opacity: 1;
}
