html,
body {
  /* overflow: hidden; */
  /* background: linear-gradient(360deg, #464447 0%, rgba(0, 0, 0, 1) 100%); */
  background-color: #eee;
}

.bg-gradient-green {
  background: linear-gradient(180deg, #82c8e5 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 300px;
  /* z-index: -1; */
  pointer-events: none;
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid black;
  /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

.loader-container {
  flex: 1 1 100%;
  align-items: center;
  place-content: center;
}

.MuiFormControl-root.sort-tags .MuiInputBase-root {
  padding: 0;
}

.modal-bg {
  background-color: #717171d9;
}

@font-face {
  font-family: 'fort';
  font-weight: normal;
  src: url(./assets/fonts/Fort\ Foundry\ -\ Industry\ Inc\ Base.otf);
}

@font-face {
  font-family: 'fort';
  font-weight: 100;
  src: url(./assets/fonts/Industry-Light.woff);
}

@font-face {
  font-family: 'fort';
  font-weight: 200;
  src: url(./assets/fonts/Industry-Medium.woff);
}

@font-face {
  font-family: 'workSans-bold';
  src: url(./assets/fonts/WorkSans-Bold.ttf);
}

@font-face {
  font-family: 'workSans-extraBold';
  src: url(./assets/fonts/WorkSans-ExtraBold.ttf);
}

@font-face {
  font-family: 'workSans-medium';
  src: url(./assets/fonts/WorkSans-Medium.ttf);
}
@font-face {
  font-family: 'roboto-medium';
  src: url(./assets/fonts/Roboto-Medium.ttf);
}

.roboto-medium {
  font-family: 'roboto-medium';
}
.workSans-bold {
  font-family: 'workSans-bold';
}

.workSans-medium {
  font-family: 'workSans-medium';
}

.workSans-extraBold {
  font-family: 'workSans-extraBold';
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bg-black-gradient {
  background: linear-gradient(360deg, #464447 0%, rgba(0, 0, 0, 1) 100%);
}
.bg-black-grediant-card {
  background: linear-gradient(360deg, rgb(65, 64, 64) 0%, rgb(18, 18, 18) 100%);
}

.main-heading {
  font-family: fort;
  letter-spacing: 8px;
  color: rgb(27, 27, 27);
  /* text-shadow: -1px 1px 2px white, 1px 1px 2px white, 1px -1px 0 white, -1px -1px 0 white; */
}

.main-heading-stroke {
  font-family: fort;
  letter-spacing: 8px;
  color: rgb(27, 27, 27);
  text-shadow: -1px 1px 2px white, 1px 1px 2px white, 1px -1px 0 white, -1px -1px 0 white;
}
.main-heading-stroke-no-spacing {
  font-family: fort;
  letter-spacing: 5px;
  color: rgb(27, 27, 27);
  text-shadow: -1px 1px 2px white, 1px 1px 2px white, 1px -1px 0 white, -1px -1px 0 white;
}
.main-heading-stroke-small {
  font-family: fort;
  letter-spacing: 5px;
  color: rgb(27, 27, 27);
  text-shadow: 0px 0.1px 0.5px white, 0.2px 0.7px 0.5px white, 1px -1px 0 white, -1px -1px 0 white;
}

.main-heading-black-stroke {
  font-family: fort;
  letter-spacing: 15px;
  color: rgb(224, 224, 224);
  text-shadow: -1px 1px 2px black, 1px 1px 2px black, 1px -1px 0 black, -1px -1px 0 black;
}

.sub-heading-black-stroke {
  font-family: fort;
  letter-spacing: 4px;
  color: rgb(224, 224, 224);
  text-shadow: -1px 1px 2px black, 1px 1px 2px black, 1px -1px 0 black, -1px -1px 0 black;
}

.sub-heading-white-stroke {
  font-family: fort;
  letter-spacing: 4px;
  color: #232431;
  text-shadow: 0px -0.6px 0.5px white, 0px 0.5px 0.5px white, -0.5px 0px 0.5px white,
    0.5px 0px 0.5px white;
}

.sub-heading-white-stroke2 {
  font-family: fort;
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #555;
  line-height: normal;
}

.stoke-text-white {
  font-family: fort;
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #fff;
  line-height: normal;
}

.sub-heading-white-stroke3 {
  font-family: fort;
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px white;
  line-height: normal;
}

.sub-heading-white-stroke2-darker {
  font-family: fort;
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #bbb;
}

.stroke-text-white {
  -webkit-text-stroke: 1px #fff;
  paint-order: stroke fill;
}

.plus-text {
  font-family: fort;
  color: rgb(224, 224, 224);
  color: rgb(27, 27, 27);
  text-shadow: -1px 1px 2px white, 1px 1px 2px white, 1px -1px 0 white, -1px -1px 0 white;
}

.medium-heading-stroke {
  font-family: fort;
  letter-spacing: 8px;
  line-height: 82px;
  color: rgb(27, 27, 27);
  text-shadow: -1px 1px 2px white, 1px 1px 2px white, 1px -1px 0 white, -1px -1px 0 white;
  opacity: 0.5;
}

.fort {
  font-family: fort;
}

.bc-item::after {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  content: '';
  height: 16px;
  width: 1px;
  background-color: #aaa;
  display: inline-block;
}

.bc-item:last-child::after {
  display: none;
}

.eh-item:nth-child(4) {
  border-right: none;
}

.eh-item:last-child {
  border-right: none;
}

.evidence-overview-table {
  width: 100%;
  border-spacing: 2px;
  border-collapse: separate;
}

.evidence-overview-table tr > th {
  height: 100px;
  min-width: 80px;
  max-width: 80px;
}

.evidence-overview-table tr > td {
  /* height: 80px;
  min-width: 80px; */
  max-width: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  border-spacing: 4px;
  color: #fff;
  font-size: 32px;
  -webkit-text-stroke: 1px #bbb;
  color: rgba(0, 0, 0, 0);
}

.evidence-overview-table tr > td.competence-scale-3 {
  background-color: #00a7e1;
  -webkit-text-stroke: 1px #fff;
}

.evidence-overview-table tr > td.competence-scale-2 {
  background-color: #bc2f2c;
  -webkit-text-stroke: 1px #fff;
}

.evidence-overview-table tr > td.competence-scale-1 {
  background-color: #394b87;
  -webkit-text-stroke: 1px #fff;
}

.evidence-overview-table tr > td.competence-scale-0 {
  background-color: #fff;
  -webkit-text-stroke: 1px #555;
}

.sb2 {
  transition: all 0.2s;
  min-width: 80px;
}

.sb2-open .sb2btn-txt {
  display: block;
  width: 160px;
  box-sizing: border-box;
}

.sb2-close .sb2btn-txt {
  display: none;
  width: 80px;
}

.sb2-open .sb2btn-ico {
  width: 72px;
}

.sb2-close .sb2btn-ico {
  width: 56px;
}

.sb2-txt-fade {
  animation: fade-sb-text 300ms both;
}

.fade-element {
  animation: fade-sb-text 200ms;
}

.sb2-close .logo-text {
  display: none;
}

.sb2-open .logo-text {
  display: block;
}

.sb2-open .logo-holder {
  min-width: 72px;
}

.sb2-close .logo-holder {
  min-width: 56px;
}

.logo-text {
  animation: fade-sb-text 300ms both;
}

.sb2-btn-active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.line-clamp-2,
.line-clamp-5 {
  /* display: box; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  visibility: visible;
}
.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
}

.doc-card-fader-l {
  background: linear-gradient(90deg, #eeeeee, rgba(0, 0, 0, 0));
}

.doc-card-fader-r {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), #eeeeee);
}

.scrollbar-hidden {
  scrollbar-width: none;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.bg-logo {
  background-image: url('./assets/images/svg/bg-logo.svg');
  background-size: 1000px;
  background-position-x: right;
  background-position-y: bottom -200px;
  background-repeat: no-repeat;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  transform: translateX(8%) translateY(25%);
  pointer-events: none;
}

.border-currentColor {
  border-color: currentColor;
}

.list-scroll::-webkit-scrollbar-track {
  background-color: #aaa;
}

.list-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #aaa;
}

.list-scroll::-webkit-scrollbar-thumb {
  background-color: black;
  width: 4px;
  margin: 4px;
  border: solid 3px #aaa;
}

.list-scroll-w::-webkit-scrollbar-track {
  background-color: #aaa;
}

.list-scroll-w::-webkit-scrollbar {
  width: 12px;
  background-color: #aaa;
}

.list-scroll-w::-webkit-scrollbar-thumb {
  background-color: white;
  width: 4px;
  margin: 4px;
  border: solid 3px #aaa;
}

.popup-arrow::before {
  content: ' ';
  height: 0px;
  width: 0px;
  border: solid 18px transparent;
  border-bottom: solid 18px white;
  position: absolute;
  top: -36px;
  right: 42px;
}

.expanden-oval-btn {
  color: black;
}

/* .expanden-oval-btn
.btn-title{
  opacity: 0;
}

.expanden-oval-btn:hover
.btn-title{
  opacity: 1;
} */

@supports (scrollbar-color: black #aaa) {
  .list-scroll {
    /* overflow-y: scroll; */
    scrollbar-color: black #aaa;
    scrollbar-width: thin;
    padding-right: 4px;
  }
  .list-scroll-w {
    /* overflow-y: scroll; */
    scrollbar-color: #ccc #777;
    scrollbar-width: thin;
    padding-right: 4px;
  }
}

.checkbox-col {
  width: 45px;
}

.list-name-col {
  width: calc(100% - 85px);
  overflow: hidden;
}

.avatar-col {
  width: 40px;
}

@media screen and (max-width: 1280px) {
  .evidence-overview-table tr > td {
    height: 60px;
    min-width: 60px;
    font-size: 24px;
  }

  .evidence-overview-table tr > th {
    height: 60px;
    min-width: 60px;
  }

  .e-f-collapse {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
}

@keyframes fade-sb-text {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.top-right-expand {
  transform-origin: calc(100% - 56px) -18px;
  animation: top-right-expand 250ms;
}

.top-expand {
  transform-origin: top center;
  animation: top-right-expand 250ms;
}

.anim-tfb {
  animation: translate-from-bottom 0.2s;
}

@keyframes translate-from-bottom {
  from {
    opacity: 0;
    transform: translateY(300%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes top-right-expand {
  from {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.file-title-webkit {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-scroll.grid.grid-cols-2 {
  grid-template-rows: auto 1fr;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-1c06rxt-MuiTypography-root {
  font-size: small;
  font-weight: 600;
}

.es-ec-shadow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.es-ec-shadow:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px 4px #14a1db;
}
